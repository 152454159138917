import React, { useEffect, useState } from "react"
import BgImg from "gatsby-background-image"
import styles from "./simple-hero.module.scss"
const SimpleHero = ({ banner, tag, category }) => {
  const { background_color, background_image, heading } = banner.elements

  const [offset, setOffset] = useState(0)
  useEffect(() => {
    const handleScroll = () => {
      setOffset(window.pageYOffset)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const bgOverlay =
    background_color.value[0] !== undefined
      ? background_color.value[0].name.replace(/\s+/g, "")
      : "Gray"
  if (background_image && background_image.value.length) {
    return (
      <div className={styles.bannerContainer}>
        <div className={`BlackBg ${styles.overlay}`}></div>
        <section
          className={styles.bannerContent}
          style={{ transform: `translateY(-${offset * 0.25}px)` }}
        >
          {tag || category ? (
            <h1>{tag ? tag : `Articles tagged with ${category}`}</h1>
          ) : (
            <h1>{heading.value}</h1>
          )}
        </section>
        <BgImg
          fluid={background_image.value[0].fluid}
          alt={background_image.value[0].description}
          className={`${styles.simpleHero}`}
          style={{ transform: `translateY(-${offset * 0.45}px)` }}
        ></BgImg>
      </div>
    )
  } else {
    return (
      <section className={`${styles.simpleHeroTextOnly} ${bgOverlay}Bg`}>
        <div className={styles.bannerContent}>
          {tag || category ? (
            <h1>{tag ? tag : category}</h1>
          ) : (
            <h1>{heading.value}</h1>
          )}
        </div>
      </section>
    )
  }
}

export default SimpleHero
